import {observer} from "mobx-react-lite";
import {useState} from "react";
import './aboutus.css';

const AboutUs = () => {

    const [privacyOpen, setPrivacyOpen] = useState(false);
    const [termsOpen, setTermsOpen] = useState(false);
    const [responsibilityOpen, setResponsibilityOpen] = useState(false);



    return (
        <div className='p-2'>
            <h2 className='about_main-title'>
                На этой странице вы найдете всю необходимую информацию о нашем сайте. Мы рады, что вы заглянули к нам и надеемся, что здесь вы найдете ответы на все ваши вопросы. Приятного изучения!
            </h2>
            <div>
              <h3 className='about-info-item-title' onClick={ () => setPrivacyOpen(!privacyOpen) }>
                  <strong>Политика конфиденциальности</strong> <svg width="9" height="9" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.707 0V2.001H5.292L0 7.293L1.414 8.707L6.706 3.415V8H8.707V0H0.707Z"></path>
              </svg>
              </h3>
              {
                  privacyOpen ?
                      <div className='about-info-item-text'>
                          <p>
                              **Политика конфиденциальности** (далее - Политика) устанавливает правила использования персональной информации, полученной от пользователей сайтов, принадлежащих и управляемых (далее - Сайты Компании), администрацией веб-сайтов (далее - Компания). Настоящая Политика конфиденциальности применяется ко всем Пользователям Сайтов Компании. Все термины и определения, встречающиеся в тексте Политики, трактуются в соответствии с действующим законодательством РФ, в частности, "Общим регламентом о защите данных". Пользователи прямо соглашаются на обработку своих персональных данных, как это описано в данной Политике. Использование Сайтов Компании означает выражение Пользователем безоговорочного согласия с Политикой и указанными условиями обработки информации. Пользователь не должен использовать Сайты Компании, если он не согласен с условиями этой Политики.
                          </p>
                          <p>
                              **1. Персональная информация, обрабатываемая Компанией**
                              1.1. Сайты Компании собирают, получают доступ и используют персональные данные Пользователя, техническую и другую информацию, связанную с Пользователями, в целях, определенных Политикой.
                              1.2. Техническая информация не является персональными данными. Компания использует "cookies" для идентификации Пользователя. "Cookies" — это текстовые файлы, доступные Компании для обработки информации о деятельности Пользователя, включая информацию о том, какие страницы посещал Пользователь и о времени, проведенном на странице. Пользователь может отключить использование cookies в настройках браузера.
                              1.3. Технической информацией также считается информация, которая автоматически передается Компании в процессе использования Сайтов Компании с использованием программного обеспечения, установленного на устройствах Пользователя.
                              1.4. Персональные данные Пользователя — это информация, которую Пользователь предоставляет Компании при заполнении форм на Сайтах Компании и последующем использовании Сайтов Компании. Информация, необходимая для предоставления Компанией, отмечена специальным образом. Другую информацию Пользователь предоставляет по своему усмотрению.
                              1.5. Компания также может обрабатывать данные, ставшие общедоступными, или подлежащие публикации или обязательному раскрытию в соответствии с законодательством РФ.
                              1.6. Компания не проверяет достоверность предоставленной Пользователем персональной информации и не имеет возможности оценивать его дееспособность. Однако Компания предполагает, что Пользователь предоставляет достоверную и достаточную персональную информацию о себе и поддерживает эту информацию в актуальном состоянии.
                          </p>
                          <p>
                              **2. Цели обработки персональной информации Пользователя**
                              2.1. Основной целью Компании при сборе персональных данных является предоставление информации и консультационных услуг Пользователям. Пользователи соглашаются, что Компания также может использовать их персональные данные для следующих целей:
                              <br/>
                              - идентификация стороны в рамках предоставляемых услуг;<br/>
                              - предоставление услуг и поддержка клиентов по запросам Пользователей;<br/>
                              - улучшение качества услуг, удобства использования, развитие Сайтов Компании, устранение технических сбоев или проблем безопасности;<br/>
                              - проведение анализа для расширения и совершенствования услуг, информационного наполнения и рекламы услуг;<br/>
                              - информирование пользователей об услугах, целевой маркетинг, обновление услуг и рекламных предложений на основе информационных предпочтений Пользователей;<br/>
                              - таргетинг рекламных материалов; отправка индивидуальных маркетинговых сообщений по электронной почте, звонкам и SMS;<br/>
                              - проведение статистических и других исследований на основе обезличенных данных.<br/>
                              2.2. Компания использует техническую информацию для целей, указанных в пункте 2.1.
                          </p>
                          <p>
                              **3. Условия и способы обработки персональной информации Пользователей и передачи ее третьим лицам**
                              3.1. Пользователь соглашается на обработку своих персональных данных путем отправки заявки (любого письменного запроса, содержащего контактную информацию).
                              3.2. Обработка персональных данных Пользователя означает сбор, запись, систематизацию, накопление, хранение, обновление, изменение, извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокировку, удаление, уничтожение персональных данных Пользователя.
                              3.3. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц.
                              3.4. Компания имеет право передавать персональную информацию Пользователя третьим лицам в следующих случаях:
                              <br/>
                              - Пользователь дал согласие на такие действия;<br/>
                              - передача необходима для использования Пользователем определенной услуги Сайтов Компании или для выполнения определенного соглашения или договора с Пользователем;
                              <br/> - передача уполномоченным органам государственной власти РФ на основаниях и в порядке, установленном законодательством РФ;
                              <br/> - для обеспечения возможности защиты прав и законных интересов Компании или третьих лиц в случаях, когда Пользователь нарушает условия договоров и соглашений с Компанией, данной Политики, или документов, содержащих условия использования конкретных услуг;
                              <br/>  - в результате обработки персональной информации Пользователя, посредством обезличивания, получены анонимные статистические данные, которые передаются третьей стороне для проведения исследований, выполнения работ или оказания услуг от имени Компании.
                              3.5. Пользователь имеет право запросить через службу поддержки Компании всю информацию, которая хранится на серверах Компании и относится непосредственно к Пользователю, включая информацию о посещениях Сайтов Компании, финансовых транзакциях и статистическом резюме.
                              3.6. Пользователь имеет право удалить свой аккаунт, содержащий персональные данные, самостоятельно или по запросу в службу поддержки Компании для удаления аккаунта. В этом случае Пользователь теряет доступ к Сайтам Компании, и его персональная информация хранится на серверах Компании в течение 30 дней для обеспечения безопасности. По истечении этого срока информация о Пользователе безвозвратно удаляется.
                          </p>
                          <p>
                              **4. Меры, используемые Компанией для защиты персональной информации Пользователя**
                              4.1. Компания принимает необходимые и достаточные правовые, организационные и технические меры для защиты персональной информации Пользователя от несанкционированного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
                          </p>
                          <p>
                              **5. Урегулирование споров**
                              5.1. Все возможные споры, возникающие из отношений, регулируемых данной Политикой, разрешаются в порядке, установленном действующим законодательством РФ.
                              5.2. Соблюдение досудебного (претензионного) порядка разрешения споров является обязательным.
                          </p>
                          <p>
                              **6. Дополнительные условия**
                              6.1. Компания имеет право вносить изменения в данную Политику конфиденциальности без согласия Пользователя.
                              6.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики конфиденциальности.
                              6.3. Продолжение использования Сайтов Компании после внесения таких изменений подтверждает согласие Пользователя с такими изменениями.
                          </p>
                          <div className='about-info-close-modal'
                               onClick={ () => setPrivacyOpen(!privacyOpen) }
                          >
                              Закрыть
                          </div>
                      </div>
                      : false
              }
          </div>

            <div>
                <h3 className='about-info-item-title' onClick={ () => setTermsOpen(!termsOpen) }>
                    <strong>Правила пользователя</strong> <svg width="9" height="9" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.707 0V2.001H5.292L0 7.293L1.414 8.707L6.706 3.415V8H8.707V0H0.707Z"></path>
                </svg>
                </h3>
                {
                    termsOpen ?
                        <div className='about-info-item-text'>
                            <p>
                                **Политика конфиденциальности** (далее - Политика) устанавливает правила использования персональной информации, полученной от пользователей сайтов, принадлежащих и управляемых (далее - Сайты Компании), администрацией веб-сайтов (далее - Компания). Настоящая Политика конфиденциальности применяется ко всем Пользователям Сайтов Компании. Все термины и определения, встречающиеся в тексте Политики, трактуются в соответствии с действующим законодательством РФ, в частности, "Общим регламентом о защите данных". Пользователи прямо соглашаются на обработку своих персональных данных, как это описано в данной Политике. Использование Сайтов Компании означает выражение Пользователем безоговорочного согласия с Политикой и указанными условиями обработки информации. Пользователь не должен использовать Сайты Компании, если он не согласен с условиями этой Политики.
                            </p>
                            <p>
                                **1. Персональная информация, обрабатываемая Компанией**
                                1.1. Сайты Компании собирают, получают доступ и используют персональные данные Пользователя, техническую и другую информацию, связанную с Пользователями, в целях, определенных Политикой.
                                1.2. Техническая информация не является персональными данными. Компания использует "cookies" для идентификации Пользователя. "Cookies" — это текстовые файлы, доступные Компании для обработки информации о деятельности Пользователя, включая информацию о том, какие страницы посещал Пользователь и о времени, проведенном на странице. Пользователь может отключить использование cookies в настройках браузера.
                                1.3. Технической информацией также считается информация, которая автоматически передается Компании в процессе использования Сайтов Компании с использованием программного обеспечения, установленного на устройствах Пользователя.
                                1.4. Персональные данные Пользователя — это информация, которую Пользователь предоставляет Компании при заполнении форм на Сайтах Компании и последующем использовании Сайтов Компании. Информация, необходимая для предоставления Компанией, отмечена специальным образом. Другую информацию Пользователь предоставляет по своему усмотрению.
                                1.5. Компания также может обрабатывать данные, ставшие общедоступными, или подлежащие публикации или обязательному раскрытию в соответствии с законодательством РФ.
                                1.6. Компания не проверяет достоверность предоставленной Пользователем персональной информации и не имеет возможности оценивать его дееспособность. Однако Компания предполагает, что Пользователь предоставляет достоверную и достаточную персональную информацию о себе и поддерживает эту информацию в актуальном состоянии.
                            </p>
                            <p>
                                **2. Цели обработки персональной информации Пользователя**
                                2.1. Основной целью Компании при сборе персональных данных является предоставление информации и консультационных услуг Пользователям. Пользователи соглашаются, что Компания также может использовать их персональные данные для следующих целей:
                                <br/>
                                - идентификация стороны в рамках предоставляемых услуг;<br/>
                                - предоставление услуг и поддержка клиентов по запросам Пользователей;<br/>
                                - улучшение качества услуг, удобства использования, развитие Сайтов Компании, устранение технических сбоев или проблем безопасности;<br/>
                                - проведение анализа для расширения и совершенствования услуг, информационного наполнения и рекламы услуг;<br/>
                                - информирование пользователей об услугах, целевой маркетинг, обновление услуг и рекламных предложений на основе информационных предпочтений Пользователей;<br/>
                                - таргетинг рекламных материалов; отправка индивидуальных маркетинговых сообщений по электронной почте, звонкам и SMS;<br/>
                                - проведение статистических и других исследований на основе обезличенных данных.<br/>
                                2.2. Компания использует техническую информацию для целей, указанных в пункте 2.1.
                            </p>
                            <p>
                                **3. Условия и способы обработки персональной информации Пользователей и передачи ее третьим лицам**
                                3.1. Пользователь соглашается на обработку своих персональных данных путем отправки заявки (любого письменного запроса, содержащего контактную информацию).
                                3.2. Обработка персональных данных Пользователя означает сбор, запись, систематизацию, накопление, хранение, обновление, изменение, извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокировку, удаление, уничтожение персональных данных Пользователя.
                                3.3. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц.
                                3.4. Компания имеет право передавать персональную информацию Пользователя третьим лицам в следующих случаях:
                                <br/>
                                - Пользователь дал согласие на такие действия;<br/>
                                - передача необходима для использования Пользователем определенной услуги Сайтов Компании или для выполнения определенного соглашения или договора с Пользователем;
                                <br/> - передача уполномоченным органам государственной власти РФ на основаниях и в порядке, установленном законодательством РФ;
                                <br/> - для обеспечения возможности защиты прав и законных интересов Компании или третьих лиц в случаях, когда Пользователь нарушает условия договоров и соглашений с Компанией, данной Политики, или документов, содержащих условия использования конкретных услуг;
                                <br/>  - в результате обработки персональной информации Пользователя, посредством обезличивания, получены анонимные статистические данные, которые передаются третьей стороне для проведения исследований, выполнения работ или оказания услуг от имени Компании.
                                3.5. Пользователь имеет право запросить через службу поддержки Компании всю информацию, которая хранится на серверах Компании и относится непосредственно к Пользователю, включая информацию о посещениях Сайтов Компании, финансовых транзакциях и статистическом резюме.
                                3.6. Пользователь имеет право удалить свой аккаунт, содержащий персональные данные, самостоятельно или по запросу в службу поддержки Компании для удаления аккаунта. В этом случае Пользователь теряет доступ к Сайтам Компании, и его персональная информация хранится на серверах Компании в течение 30 дней для обеспечения безопасности. По истечении этого срока информация о Пользователе безвозвратно удаляется.
                            </p>
                            <p>
                                **4. Меры, используемые Компанией для защиты персональной информации Пользователя**
                                4.1. Компания принимает необходимые и достаточные правовые, организационные и технические меры для защиты персональной информации Пользователя от несанкционированного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
                            </p>
                            <p>
                                **5. Урегулирование споров**
                                5.1. Все возможные споры, возникающие из отношений, регулируемых данной Политикой, разрешаются в порядке, установленном действующим законодательством РФ.
                                5.2. Соблюдение досудебного (претензионного) порядка разрешения споров является обязательным.
                            </p>
                            <p>
                                **6. Дополнительные условия**
                                6.1. Компания имеет право вносить изменения в данную Политику конфиденциальности без согласия Пользователя.
                                6.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики конфиденциальности.
                                6.3. Продолжение использования Сайтов Компании после внесения таких изменений подтверждает согласие Пользователя с такими изменениями.
                            </p>
                            <div className='about-info-close-modal'
                                 onClick={ () => setTermsOpen(!termsOpen) }
                            >
                                Закрыть
                            </div>
                        </div>
                        : false
                }
            </div>

            <div>
                <h3 className='about-info-item-title' onClick={ () => setResponsibilityOpen(!responsibilityOpen) }>
                    <strong>Отказ от ответственности</strong> <svg width="9" height="9" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.707 0V2.001H5.292L0 7.293L1.414 8.707L6.706 3.415V8H8.707V0H0.707Z"></path>
                </svg>
                </h3>
                {
                    responsibilityOpen ?
                        <div className='about-info-item-text'>
                            <p>
                                Если вам нужна дополнительная информация или у вас есть вопросы о нашем отказе от ответственности, свяжитесь с нами по электронной почте: Gever.menrevons@bigmir.net.
                            </p>
                            <p>
                                Вся информация на этом сайте предоставляется добросовестно и исключительно в ознакомительных целях. Мы не гарантируем полноту, надежность или точность информации. Любые действия, предпринимаемые на основе информации с этого сайта, вы совершаете на свой страх и риск. Мы не несем ответственности за потери или убытки, связанные с использованием нашего сайта.
                            </p>
                            <p>
                                После перехода на внешние сайты, они могут иметь иные политики конфиденциальности и условия использования.
                            </p>
                            <div className='about-info-close-modal'
                                 onClick={ () => setResponsibilityOpen(!responsibilityOpen) }
                            >
                                Закрыть
                            </div>
                        </div>
                        : false
                }
            </div>

            <div>
                <h3 className='about_main-title'>
                    Контактные данные <br/>
                    Gever.menrevons@bigmir.net <br/>
                    ул. Карла Фукса, 10, Казань, Респ. Татарстан, 420111 <br/>
                    Наш номер: 7 (495) 695-31-11
                </h3>

            </div>
        </div>
    )


}
export default observer(AboutUs);