import React, {useContext, useEffect, useRef, useState} from 'react';
import Marquee from "react-fast-marquee";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiamond} from "@fortawesome/free-solid-svg-icons";
import {Context} from "../../../index";
import {observer} from "mobx-react-lite";

const News = () => {

    const {storeblog} = useContext(Context)
    const lastEL = useRef()
    const logoShort = require('../../images/logo-short.png');


    return (
        <div className='d-flex justify-content-start border-bottom'>
            <div className=''>
                <img className='short_logo_news_line' src={logoShort} alt=""/>
            </div>
            <Marquee autoFill={true} pauseOnHover={true}>
                {
                    storeblog.posts.map((e, i) => {
                        return (
                            <div key={i} className='d-flex align-items-center'>
                                <p  className="inline-block my-0 mx-2 news-item">{e.title}</p>
                                <FontAwesomeIcon key={i} icon={faDiamond} style={{height: '6px'}} className='text-danger px-2'/>
                            </div>
                        )
                    })
                }
            </Marquee>
        </div>
    );
};
export default observer(News);
