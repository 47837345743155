import React, { FC, useContext, useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import CopyMessage from '../UI/CopyMessage/CopyMessage';
import EditBannerModal from "./EditBannerModal";

interface BannerStatisticItemProps {
    id: string,
    seoName: string,
    mainImg: string,
    view: string,
    uniqView: string,
    click: string,
    ctr: string,
    registration: string,
    cr: string,
    atricles: []
}




const BannerStatisticItem: FC<BannerStatisticItemProps> = (props) => {
    const { postStore } = useContext(Context)
    const [visible, setVisible] = useState<boolean>(false)
    const [ctrUpdate, setCtrUpdate] = useState<string>('0')
    const [crUpdate, setCrUpdate] = useState<string>('0')

    const [copied, setCopied] = useState(false);
    const handleCopy = (textToCopy: string) => {
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setCopied(true);
                console.log('copy');
                setTimeout(() => setCopied(false), 1000); // Показує повідомлення на 2 секунди
            })
            .catch(err => {
                console.error("Помилка при копіюванні:", err);
            });
    };

    useEffect(()=>{
        let ctrItem = parseFloat(props.ctr).toFixed(2) + '%';
        let crItem = parseFloat(props.cr).toFixed(2) + '%';
        setCtrUpdate(ctrItem)
        setCrUpdate(crItem)
    })


    return (
        <>
            {copied && <CopyMessage />}
            <tr className='banner_info_line'>
                <td className='dashboard-list-item_main'>
                    <span className='dashboard-list-item_main_item' onClick={() => handleCopy(props.id)}>
                        {props.id}
                        <i className={`bx bx-copy`}></i>
                    </span>
                    <hr />
                    <span className='dashboard-list-item_main_item' onClick={() => handleCopy(props.seoName)}>
                        {props.seoName}
                        <i className={`bx bx-copy`}></i>
                    </span>
                </td>
                <td className='banner_item_img_box'>
                    <img className='banner_item_img_box-img' src={props.mainImg}
                         alt={props.id} />
                </td>
                <td className='statistic_banner'>{props.view}</td>
                <td className='statistic_banner'>{props.uniqView}</td>
                <td className='statistic_banner'>{props.click}</td>
                <td className='statistic_banner'>{ctrUpdate}</td>
                <td className='statistic_banner'>{props.registration}</td>
                <td className='statistic_banner'>{crUpdate}</td>
                <td className='statistic_banner'><i style={{fontSize: "xx-large"}} className='bx bx-show-alt'></i></td>

            </tr>
        </>
    );
};

export default observer(BannerStatisticItem);