import React, { FC, useContext, useState } from 'react';
import { observer } from "mobx-react-lite";
import Editoor from "../Posts/Editor/Editoor";
import { Context } from "../../index";
import MyInput from "../UI/MyInput/MyInput";
import { useNavigate } from "react-router-dom";
import { RoutesList } from "../../routes";
import { useFetching } from '../../lib/useFetching';
import Loading from '../UI/Loading/Loading';

// interface ValidError {
//     isError: boolean;
//     errorsFields: number[];
// }

const CreateBanner: FC = () => {
    const { store } = useContext(Context)
    const { postStore } = useContext(Context)
    const navigate = useNavigate();

    const [modalVisible, setModalVisible] = useState(true)
    const [visible, setVisible] = useState(false)
    const [editorHtml, setEditorHtml] = useState('');
    const [priority, setPriority] = useState<string>('');
    const [title, setTitle] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [bannerLink, setBannerLink] = useState<string>('')
    // const [isTop, setIsTop] = useState<any>(false)
    // const [isValidError, setIsValidError] = useState<boolean>(false)
    const [validErrorArr, setValidErrorArr] = useState<number[]>([])


    const handleChoosePriority = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (/^[0-9]*$/.test(e.target.value)) {
            setPriority(e.target.value);
        }
    }

    const checkValidBanner = (arrValues: string[]) => {
        const checkScriptAvailible = (text: string) => {
            let scriptReg = /<script/;
            return scriptReg.test(text)
        }

        let arrErrorsId: number[] = []

        arrValues.forEach((val, i) => {
            let valLength = val.trim().length
            if (!valLength || checkScriptAvailible(val)) {
                arrErrorsId.push(i);
            }
        })
        setValidErrorArr(arrErrorsId);
        return arrErrorsId.length
    }

    const CreatePost = async () => {
        const thisDate = new Date()

        const date = thisDate.toLocaleDateString()
        const time = thisDate.toLocaleTimeString()

        const post = editorHtml;
        const resultLink = () => {
            if (bannerLink[0] == '/') {
                return bannerLink;
            } else if (bannerLink.indexOf('https://') !== -1) {
                return bannerLink;
            } else {
                return 'https://' + bannerLink;
            }
        };
        const link = resultLink();

        if (checkValidBanner([title, description, bannerLink, priority])) return;

        const response = await postStore.addBanner(date, time, title.trim(), description.trim(), post, link.trim(), priority.trim())
        navigate(RoutesList.BANNERS_LIST_ROUTER)
        store.setSideBarActiveID(1)
    }

    const { fetching, isError, isLoading } = useFetching(CreatePost);

    return (

        <div className='p-4 bg-light rounded-md '>
            {isLoading && <Loading />}
            <div className='flex justify-between pb-4'>
                <div className='w-[500px] flex justify-between'>
                    <h3>Додавання банер</h3>
                    <div className='h-9'>
                        <MyInput
                            type='text'
                            id={'1'}
                            value={title}
                            placeholder={'Заголовок'}
                            icon={'text'}
                            void={(e) => setTitle(e.target.value)}
                        />
                        {
                            validErrorArr.some((v) => v === 0) &&
                            <div className='input_error mt-1 px-2 py-1'>
                                Поле порожнє або має забороненні символи
                            </div>
                        }
                    </div>

                    <div className='h-9'>
                        <MyInput
                            type='text'
                            id={'2'}
                            value={description}
                            placeholder={'Опис банера'}
                            icon={'text'}
                            void={(e) => setDescription(e.target.value)}
                        />
                        {
                            validErrorArr.some((v) => v === 1) &&
                            <div className='input_error mt-1 px-2 py-1'>
                                Поле порожнє або має забороненні символи
                            </div>
                        }
                    </div>
                    <div className='h-9'>
                        <MyInput
                            type='text'
                            id={'3'}
                            value={bannerLink}
                            placeholder={'Посилланя на офер'}
                            icon={'link-alt'}
                            void={(e) => setBannerLink(e.target.value)}
                        />
                        {
                            validErrorArr.some((v) => v === 2) &&
                            <div className='input_error mt-1 px-2 py-1'>
                                Поле порожнє або має забороненні символи
                            </div>
                        }
                    </div>
                    <div className='pt-3'>
                        <div className="input-group">
                            <MyInput
                                placeholder={'Пріоритетність'}
                                type={'text'}
                                value={priority}
                                void={handleChoosePriority}
                            />
                        </div>
                        {
                            validErrorArr.some((v) => v === 3) &&
                            <div className='input_error mt-1 px-2 py-1'>
                                Поле порожнє або має забороненні символи
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className='h-80 bg-white text-black'>
                <Editoor
                    editorHtml={editorHtml}
                    setEditorHtml={setEditorHtml}
                    placeholder='Write here...' />
            </div>
            <div className='flex justify-between w-full pt-4'>
                <button
                    onClick={fetching}
                    className='btn btn-secondary rounded-md'
                    disabled={isLoading}
                >
                    Додати банер
                </button>
                {/* <button
                    onClick={() => { checkValidBanner([title, description, bannerLink, priority]) }}
                    className='btn btn-secondary rounded-md'
                >
                    {validErrorArr.length}
                </button> */}
            </div>
        </div>
    );
};

export default observer(CreateBanner);