import React, {FC, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import PostItem from '../components/Posts/PostItem';
import {API_URL} from '../http';
import {Context} from "../index";
import useScroll from "../lib/useScroll";
import PostService from "../services/PostService";
import {IPost} from "../models/IPost";
import {PostsResponse} from "../models/response/PostsResponse";
import axios from "axios";

interface Post {
    title: string;
    _id: string;
    priority: string;
    isActive: boolean;
    category: string,
    description: string,
    seoName: string,
    post: string,
}

const PostList: FC = () => {
    const {postStore} = useContext(Context)

    const [page, setPage] = useState(1)

    const [totalPages, setTotalPages] = useState<number[]>([])
    const limit = 10;

    const parentRef = useRef<any>()
    const childRef = useRef<any>()

    const pages = useMemo(() => {
        const pagesArray = [];
        for (let i = 0; i < postStore.pages; i++) {
            pagesArray.push(i + 1);
        }
        setTotalPages(pagesArray);
        return pagesArray;
    }, [postStore.pages]);

    useEffect(() => {
        let htmlRoot = document.getElementsByTagName("html");
        htmlRoot[0].classList.add('dark-theme');

        postStore.getPosts(page, limit)
            .then( e => console.log(e))
    }, [page])

    const handleClickPage = (i:number) => {
        setPage(i)
    }

    return (
        <div className=''>
            <div className='w-full h-1 bg-slate-400 rounded-md'></div>
            <div className=''>
                Posts List
                <hr/>
                <div className='card'>
                    <div className='card-body'>
                        <table className='table mb-0 table-dark table-striped'>
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Main Img</th>
                                <th scope="col">Заголовок</th>
                                <th scope="col">Топ</th>
                                <th scope="col">Показати</th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <tbody ref={parentRef}>
                            {postStore.posts.map((e, i) =>
                                <PostItem
                                    title={e.title}
                                    id={e._id}
                                    priority={e.priority}
                                    isActive={e.isActive}
                                    category={e.category}
                                    description={e.description}
                                    seoName={e.seoName}
                                    post={e.post}
                                    key={i}
                                    mainImg={e.mainImg}
                                />
                            )
                            }

                            <nav aria-label="...">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <span className='page-link' style={{color : '#12181a'}}>i </span>
                                    </li>

                                    {
                                        totalPages.map( (e,i) => {
                                            return(
                                                <li className="page-item">
                                                    <button
                                                        onClick={()=> handleClickPage(i + 1)}
                                                    className={(page == i + 1) ? ' page-link bg-secondary' : 'page-link'} >{i + 1}</button></li>
                                            )
                                        })
                                    }

                                    <li className="page-item">
                                        <span className='page-link' style={{color : '#12181a'}}>i </span>
                                    </li>


                                </ul>
                            </nav>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(PostList);
