import React, { FC, useContext, useState } from 'react';
import { Context } from "../../index";
import MyInput from "../UI/MyInput/MyInput";
import Editoor from "../Posts/Editor/Editoor";
import PostStore from "../../store/postStore";
import { useFetching } from '../../lib/useFetching';
import Loading from '../UI/Loading/Loading';

interface EditBannerModalProps {
    bannerId: string,
    post: string,
    title: string,
    isActive: boolean,
    priority: string,
    description: string,
    link: string,
    handlePopUpOpen: () => void,
}

const EditBannerModal: FC<EditBannerModalProps> = (props) => {
    const { postStore } = useContext(Context)

    const [modalVisible, setModalVisible] = useState(true)
    const [visible, setVisible] = useState(false)
    const [editorHtml, setEditorHtml] = useState(props.post);
    const [title, setTitle] = useState<string>(props.title);
    const [priority, setPriority] = useState<string>(props.priority);
    const [description, setDescription] = useState<string>(props.description);
    const [isActive, setIsActive] = useState<boolean>(props.isActive);
    const [link, setLink] = useState<string>(props.link);

    const [validErrorArr, setValidErrorArr] = useState<number[]>([]);


    const checkValidBanner = (arrValues: string[]) => {
        const checkScriptAvailible = (text: string) => {
            let scriptReg = /<script/;
            return scriptReg.test(text)
        }
        let arrErrorsId: number[] = []
        arrValues.forEach((val, i) => {
            let valLength = val.toString().trim().length
            
            if (!valLength || checkScriptAvailible(val)) {
                arrErrorsId.push(i);
            }
        })
        setValidErrorArr(arrErrorsId);
        return arrErrorsId.length
    }

    const setArticleIsActive = () => {
        setIsActive(!isActive)
    }

    const handleChoosePriority = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (/^[0-9]*$/.test(e.target.value)) {
            setPriority(e.target.value);
        }
    }

    const Close = () => {
        props.handlePopUpOpen()
    }

    const EditBanner = async () => {
        const thisDate = new Date()

        const id = props.bannerId;
        const date = thisDate.toLocaleDateString()
        const time = thisDate.toLocaleTimeString()

        const post = editorHtml;

        if (checkValidBanner([title, description, link, priority])) return;

        const response = await postStore.editBanner(id, date, time, isActive, title.trim(), description.trim(), post, link.trim(), priority)
        props.handlePopUpOpen()
        postStore.getBanners(1, 20)
        console.log(response)
    }

    const { fetching, isError, isLoading } = useFetching(EditBanner);

    return (

        <div className="modal show edit_post" role="dialog">
            {isLoading && <Loading />}
            <div className="modal-dialog" role="document">
                <div className="modal-content p-4">
                    <div className='d-flex flex-column align-items-start'>
                        <h3>Редагування банера</h3>
                        <div className='h-9 w-100'>
                            <MyInput
                                type='text'
                                id={'1'}
                                value={title}
                                placeholder={'Заголовок'}
                                icon={'text'}
                                void={(e) => setTitle(e.target.value)}
                            />
                            {
                                validErrorArr.some((v) => v === 0) &&
                                <div className='input_error mt-1 px-2 py-1'>
                                    Поле порожнє або має забороненні символи
                                </div>
                            }
                        </div>
                        <div className='h-9 w-100'>
                            <MyInput
                                type='text'
                                id={'2'}
                                value={description}
                                placeholder={'Опис статті'}
                                icon={'text'}
                                void={(e) => setDescription(e.target.value)}
                            />
                            {
                                validErrorArr.some((v) => v === 1) &&
                                <div className='input_error mt-1 px-2 py-1'>
                                    Поле порожнє або має забороненні символи
                                </div>
                            }
                        </div>
                        <div className='pt-3 w-100'>
                            <MyInput
                                type='text'
                                id={'3'}
                                value={link}
                                placeholder={'Посилланя на офер'}
                                icon={'link-alt'}
                                void={(e) => setLink(e.target.value)}
                            />
                            {
                                validErrorArr.some((v) => v === 2) &&
                                <div className='input_error mt-1 px-2 py-1'>
                                    Поле порожнє або має забороненні символи
                                </div>
                            }
                        </div>
                        <div className="input-group">
                            <MyInput
                                placeholder={'Пріоритетність'}
                                type={'text'}
                                value={priority}
                                void={handleChoosePriority}
                            />
                            {
                                validErrorArr.some((v) => v === 3) &&
                                <div className='input_error mt-1 px-2 py-1'>
                                    Поле порожнє або має забороненні символи
                                </div>
                            }
                        </div>
                    </div>
                    <div className='h-9 pt-4'>
                        <div className="form-check">
                            <input className={"form-check-input " + (isActive ? 'bg-black border-black' : '')}
                                onChange={setArticleIsActive}
                                checked={isActive}
                                type="checkbox"
                                value=""
                                id="flexCheckDefault2"
                            />
                            <label className="form-check-label" htmlFor="flexCheckDefault2">Показати</label>
                        </div>
                    </div>
                    <div className='h-80 bg-white text-black'>
                        <Editoor
                            editorHtml={editorHtml}
                            setEditorHtml={setEditorHtml}
                            placeholder='Write here...' />
                    </div>
                    <div className='flex justify-between w-75 pt-4'>
                        <button
                            onClick={Close}
                            className='btn btn-light rounded-md w-25'
                        >
                            Назад
                        </button>
                        <button
                            onClick={fetching}
                            className='btn btn-secondary rounded-md mx-2 w-25'
                            disabled={isLoading}
                        >
                            Зберегти зміни
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default EditBannerModal;