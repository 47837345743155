import React, {FC, useContext, useEffect, useState} from 'react';
import {Context} from "./index";
import {observer} from "mobx-react-lite";
import './publickArticle/css/style.css';
import './publickArticle/css/custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthPanel from "./AuthPanel";
import Loading from "./components/UI/Loading/Loading";
import MyModal from "./components/UI/MyModal/MyModal";
import './components/CSS/css/app.css';
import './components/CSS/css/icons.css';
import './components/CSS/css/header-colors.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/CSS/css/dark-theme.css';
import './components/CSS/css/pace.min.css';
import PublickPanel from "./PublickPanel";
import {Route, Routes, useLocation} from "react-router-dom";
import AuthPage from "./pages/AuthPage";


const App: FC = () => {
    const {storeblog, store, postStore} = useContext(Context)
    const location = useLocation()
    const [visible, setVisible] = useState(true)

    useEffect(() => {
        if (location.pathname.startsWith('/admin')) {
            if (localStorage.getItem('token')) {
                store.checkIsAuth()
            }

        }
        storeblog.GetUrlParams();

    }, [])

    return (
        <div className='col'>
            {store.isLoading && <Loading/>}
            {store.isError !== '' &&
                <MyModal visible={visible} void={() => setVisible(false)} description={store.isError}/>}
            {store.isAuth
                ? <AuthPanel/>
                : <PublickPanel/>
            }
        </div>
    );
};

export default observer(App);