import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import Loading from "../components/UI/Loading/Loading";
import { Context } from "../index";
import BannerStatisticItem from "../components/Banners/BannerStatisticItem";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/dark.css";
import { formatDate } from "../lib/formatDate";

const BannerStatisticPage: FC = () => {
    const { storeblog } = useContext(Context);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState<number[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const limit = 10;
    const parentRef = useRef<any>(null);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const handleClickPage = (i: number) => {
        setPage(i);
    };

    useEffect(() => {
        const now = new Date();
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const currentDay = endDate.toISOString();
        setStartDate(firstDayOfMonth);
        storeblog
            .getBannersStatistic(firstDayOfMonth.toISOString(), currentDay)
            .then((e) => setLoading(false))
            .catch((e) => console.log(e));
    }, []);

    const onSetStartDate = (dateArr: Date[]) => {
        const selectedDate = dateArr[0];
        const customDate = formatDate(selectedDate);
        setStartDate(customDate);
    };

    const onSetEndtDate = (dateArr: Date[]) => {
        const selectedDate = dateArr[0];
        const customDate = formatDate(selectedDate);
        setEndDate(customDate);
    };

    const getPeriodData = () => {
        setLoading(true);
        storeblog
            .getBannersStatistic(
                new Date(startDate).toISOString(),
                new Date(endDate).toISOString()
            )
            .then((e) => setLoading(false))
            .catch((e) => console.log(e))
            .finally(() => setLoading(false));
    };

    return (
        <div>
            <div className="w-full h-1 bg-slate-400 rounded-md"></div>
            <div>
                <h4>Banners List</h4>
                <hr />
                <div className="card">
                    <div className="card-header d-flex align-items-end gap-2">
                        <div className="col-lg-3">
                            <label
                                htmlFor={"date_from"}
                                className="form-label mt-2 mb-1 text-secondary"
                            >
                                Date From
                            </label>
                            <Flatpickr
                                value={startDate}
                                id="date_from"
                                className="form-control p-1 cursor-pointer"
                                onChange={onSetStartDate}
                                options={{
                                    dateFormat: "Y-m-d", // Формат дати
                                }}
                            />
                        </div>
                        <div className="col-lg-3">
                            <label
                                htmlFor={"date_to"}
                                className="form-label mt-2 mb-1 text-secondary"
                            >
                                Date To
                            </label>
                            <Flatpickr
                                value={endDate}
                                id="date_to"
                                className="form-control p-1 cursor-pointer"
                                onChange={onSetEndtDate}
                                options={{
                                    dateFormat: "Y-m-d", // Формат дати
                                }}
                            />
                        </div>
                        <div className="col-lg-3">
                            <button
                                onClick={getPeriodData}
                                className="btn btn-secondary rounded-md w-100"
                            >
                                Пошук
                            </button>
                        </div>
                    </div>

                    <div className="card-body">
                        {loading ? (
                            <Loading />
                        ) : (
                            <table className="table mb-0 table-dark table-striped table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th
                                            style={{ width: "22%" }}
                                            scope="col"
                                        >
                                            #
                                        </th>
                                        <th
                                            style={{ width: "12.5%" }}
                                            scope="col"
                                        >
                                            Main Img
                                        </th>
                                        <th scope="col">Перегляди</th>
                                        <th scope="col">Унік Перегляд</th>
                                        <th scope="col">Кліки</th>
                                        <th scope="col">CTR</th>
                                        <th scope="col">Реєстрації</th>
                                        <th scope="col">CR</th>
                                        <th scope="col">
                                            <i className="bx bx-info-circle"></i>{" "}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody ref={parentRef}>
                                    {storeblog.bannersStatisticData.map((e) => {
                                        return (
                                            <BannerStatisticItem
                                                key={e.id}
                                                id={e.id}
                                                seoName={e.seoName}
                                                mainImg={e.mainImg}
                                                view={e.view}
                                                uniqView={e.uniqView}
                                                click={e.click}
                                                ctr={e.ctr}
                                                registration={e.registration}
                                                cr={e.cr}
                                                atricles={e.article}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        )}
                        <nav aria-label="...">
                            <ul className="pagination mt-3">
                                <li className="page-item">
                                    <span
                                        className="page-link"
                                        style={{ color: "#12181a" }}
                                    >
                                        i
                                    </span>
                                </li>
                                {totalPages.map((e, i) => (
                                    <li key={i} className="page-item">
                                        <button
                                            onClick={() =>
                                                handleClickPage(i + 1)
                                            }
                                            className={
                                                page === i + 1
                                                    ? "page-link bg-secondary"
                                                    : "page-link"
                                            }
                                        >
                                            {i + 1}
                                        </button>
                                    </li>
                                ))}
                                <li className="page-item">
                                    <span
                                        className="page-link"
                                        style={{ color: "#12181a" }}
                                    >
                                        i
                                    </span>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(BannerStatisticPage);
